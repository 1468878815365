.login-box {
  /* position: absolute; */
  margin-top: 20px;
  /* left: 50%; */
  /* width: 350px; */
  max-width: 400px;
  padding: 40px;
  /* transform: translate(-50%, -50%); */
  background: rgba(24, 20, 20, 0.987);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
}



.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label
{
  top: -20px;
  left: 0;
  color: #bdb8b8;
  font-size: 12px;
}

textarea::placeholder {
  color: white; /* Change this to your desired color */
}


.login-box form button , #btn2{
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.login-box button:hover ,#btn2:hover{
  cursor: pointer;
  background:#3B71CA;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px  #3B71CA,
              0 0 25px #3B71CA,
              0 0 50px #3B71CA,
              0 0 100px #3B71CA;
}

.login-box button span, #movingline {
  position: absolute;
  display: block;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,100% {
    left: 100%;
  }
}

.login-box button :nth-child(1) , #movingline{
  bottom: 2px;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #3B71CA);
  animation: btn-anim1 2s linear infinite;
}