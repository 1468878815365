.scroller {
  width: 100%; /* Occupy the full width */
  overflow: hidden; /* Prevent horizontal scrolling */
  white-space: nowrap; /* Prevents line breaks */
}

.scroller__inner {
  display: flex;
  gap: 8rem; /* Adjust gap to ensure proper spacing */
  padding-block: 1rem;
  animation: scroll var(--_animation-duration, 10s) linear infinite; /* Adjusted duration for smoothness */
}

.scroller__inner img {
  height: 50px; /* Maintain consistent height */
}

.scroller[data-animated="true"] {
  -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-direction="right"] {
  --_animation-direction: forwards; /* Scroll from left to right */
}

.scroller[data-speed="fast"] {
  --_animation-duration: 10s; /* Fast speed */
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s; /* Slow speed */
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%); /* Adjusted to scroll from left to right */
  }
}
